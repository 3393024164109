import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import RadarChart from './RadarChart';
import { Card, Col, Row, Slider } from 'antd';
import StarTips from './StarTips';
import StarEditor from './StarEditor';
import { modyfyStarEduData } from '../../../http/studentApi';
import { message } from 'antd';

const EditStar = ({ header, starS, setStarS, tipsTextData, starLayer, saveData, navigate, yellowDesc, greenDesc, editable = true }) => {

    const chartSize = 500;
    const numberOfScale = 5;

    const [tipsDataName, setTipsDataName] = useState('')
    const [tipsDataChoice, setTipsDataChoice] = useState(0)
    const [stateSaveButton, setStateSaveButton] = useState(true)

    const change = (name, value) => {
        starS[starLayer][name] = value
        //        setStarS(JSON.parse(JSON.stringify(star)))
        setTipsDataName(name)
        setTipsDataChoice(value)
        setStateSaveButton(false)

        //    console.log("starS",starS);
    }

    const clickSave = () => {
        setStateSaveButton(true)
        saveData(starS)
        //        saveEducationRouteData();
    }

    const saveEducationRouteData = async () => {
        try {
            const formData = new FormData()
            //todo убрать лишние данные
            formData.append('starData', JSON.stringify(starS))
            modyfyStarEduData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }




    const validate = () => {
        if (!starS) return false;

        let ret = true
        if (starS.constructor.name != "Array") {
            ret = false
        }
        try {
            if (Object.keys(starS[0]) < 1) {
                ret = false
            }
            if (Object.keys(starS[1]) < 1) {
                ret = false
            }
        } catch (error) {
            ret = false
        }
        return ret
    }
    if (!validate()) {
        return (<>Нет данных</>)
    } else {
        return (<>
            <Row>
                <Col flex={6}>

                    <div style={{ fontSize: '20px', color: "#75B53E" }}>{header}</div>
                    <RadarChart
                        route={starS}
                        size={chartSize}
                        numberOfScale={numberOfScale}
                        navigate={navigate}
                        tipsData={tipsTextData}
                        dotMode={/* !editable &&  */tipsTextData ? "tips" : "normal"}
                    />
                </Col>
                {editable &&
                    <Col flex={18}>
                        <StarEditor
                            starS={starS}
                            starLayer={starLayer}
                            changeRayData={change}
                        />
                        {/*                         {Object.keys(starS[starLayer]).map(rayKey =>
                            <div>
                                <h6>{rayKey}</h6>
                                <Slider
                                    trackStyle={starLayer == 1 ? { backgroundColor: "#75B53E" } : { backgroundColor: "#ffd110" }}
                                    defaultValue={starS[starLayer][rayKey]}
                                    // tooltip={{ open: true }}
                                    max={4}
                                    min={1}
                                    onChange={(value) => change(rayKey, value)}
                                />
                            </div>
                        )} */}
                        <Container style={{ textAlign: 'right' }}>
                            <Button style={{ marginTop: '20px' }} variant="outline-success" onClick={() => clickSave()} disabled={stateSaveButton}>Сохранить</Button>
                        </Container>
                    </Col>}
            </Row>


            <Row>
                <Col span={1}>
                    <div style={{ backgroundColor: "#ffd110", height: '16px', width: '16px' }}></div>
                </Col>
                <Col span={13}>{yellowDesc}</Col>
            </Row>
            <Row>
                <Col span={1}>
                    <div style={{ backgroundColor: "#75B53E", height: '16px', width: '16px' }}></div>
                </Col>
                <Col span={23}>{greenDesc}</Col>
            </Row>
            <Row /* justify="space-around" align="middle" */>
                <Col span={14}>
                </Col>
                {Boolean(editable & tipsTextData != undefined && tipsTextData[tipsDataName]) && <StarTips tipsData={tipsTextData} name={tipsDataName} choice={tipsDataChoice} data={tipsTextData[tipsDataName][tipsDataChoice - 1]} />}
            </Row>
        </>
        );
    }
};

export default EditStar;