import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createSSubject, deleteSSubject, fetchSSubjects, modyfySSubject } from '../../../http/adminApi';
import { Card } from 'antd';

const AdminRefSubjectStar = observer(() => {
    const { admin } = useContext(Context)
    //const [starSubjects , setStarSubjects] = useState([]) 

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const dataS = await fetchSSubjects()
        admin.setStarSubjects(dataS)
        //setStarSubjects(dataS)
    }

    const addSSubject = () => {
        /*         const data = createSSubject().then(id => 
                    setStarSubjects([...starSubjects, {name: '', id: id}])) */
        const data = createSSubject().then(id =>
            admin.setStarSubjects([...admin.starSubjects, { name: '', id: id }]))
    }

    const removeSSubject = (id) => {
        /*         const data = deleteSSubject(id)
                setStarSubjects(starSubjects.filter(i => i.id !== id)) */
        const data = deleteSSubject(id)
        admin.setStarSubjects(admin.starSubjects.filter(i => i.id !== id))
    }

    const changeSSubject = (name, id) => {
        /*         const data = modyfySSubject(name, id)
                setStarSubjects(starSubjects.map(i => i.id === id ? { ...i, ['name']: name } : i)) */
        const data = modyfySSubject(name, id)
        admin.setStarSubjects(admin.starSubjects.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    return (
        <Card title={'Создание и удаление лучей'}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Название луча</th>
                        <th><Button onClick={() => addSSubject()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.starSubjects.map(ssubject =>
                        <tr key={ssubject.id}>
                            <td>
                                <FormControl
                                    placeholder='Введите название луча'
                                    value={ssubject.name}
                                    onChange={(e) => changeSSubject(e.target.value, ssubject.id)}
                                />
                            </td>
                            <td><Button onClick={() => removeSSubject(ssubject.id)} className='float-end'><FaMinus className="d-flex justify-content-between" /></Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default AdminRefSubjectStar;