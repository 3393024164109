import { Card, Checkbox, Col, DatePicker, Row } from "antd";
import { Typography } from 'antd';
import { Key, useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createDescription, createDescriptionV2, deleteDescription, deleteDescriptionV2, fetchDescriptions, fetchDescriptionsV2, modyfyDescriptionO, modyfyDescriptionOV2, oneDescription } from "../../http/teacherApi";
import dayjs from 'dayjs'
import Editor from "../utilites/quill/EditorBlur";
import FilesUpload from "../utilites/FilesUpload";
import { Form, redirect, useLoaderData, useSubmit } from "react-router-dom";
import { key2number } from "../teacher/syllabus/Modules2";
import { Button } from "react-bootstrap";
import Select from 'react-select'
import MyColoredSelect from "../MyColoredSelect";
import { fetchColors } from "../../http/adminApi";

const { Paragraph } = Typography;
dayjs.locale('ru')

interface DescriptyonType {
    id: number,
    index?: string,
    description?: string,
    text?: string,
    workType?: number,
    selfScore?: boolean,
    fileList?: [],
}

export async function loaderDescriptionCardList({ params }: any) {
    console.log("DescriptionCardList", params);

    const { moduleID, themeID, descriptionID } = params;

/*     const raw_key: string[] = []
    raw_key.push(moduleID);
    if (themeID) raw_key.push(themeID);
    if (themeID) raw_key.push(themeID);
    if (descriptionID) raw_key.push(descriptionID);

    const key = raw_key.join('-');

 */    if (themeID) {
        const descriptions = await fetchDescriptionsV2(themeID);
        const rawColors = await fetchColors();
        const colors = rawColors.map((item: any) => { return { value: item.id, label: `${item.name} (${(item.avg) ? 'Средний балл' : 'Суммарный балл'}${(item.result) ? ', В итог' : ''})`, color: item.color } });
        return { descriptions, colors/* key */ }
    }
    return { /* key */ };
}

export async function createDescriptionCardList({ params }: any) {
    const { routeID, moduleID, themeID } = params;
    const ret = await createDescriptionV2(themeID);
    console.log("new DescriptionID:", ret);
    return redirect(`../${moduleID}/${themeID}`); // /${ret} // ${moduleID}/${themeID}/

}


export async function destroyDescriptionCardList({ params, request }: any) {
    console.log("destroyDescriptionCardList", params, request);
    const { themeID, routeID, moduleID, descriptionID } = params
    if (descriptionID) {
        await deleteDescriptionV2(descriptionID);

    }
    //    return redirect(`../../${moduleID}/${themeID}/${routeID}`);
    return redirect(`../${moduleID}/${themeID}`);
}


export async function actionDescriptionCardList({ params, request }: any) {
    console.log("actionDescriptionCardList", params, request);
    const { descriptionID } = params;

    const formData = await request.formData();
    const { query } = Object.fromEntries(formData);
    console.log("query: ", query);

    if (query) {
        const { text, description, index, descriptionID, workType, selfScore } = JSON.parse(query);
        if (descriptionID) {
            modyfyDescriptionOV2({ descriptionID, text, description, index, workType, selfScore });
            return redirect(".");
        }
    }

    return null
}


const DescriptionCardList = (/* { id }: number | any */) => {

    const submit = useSubmit();

    const { descriptionID, descriptions, key, colors } = useLoaderData() as any;

    const { id, _type, selected, expanded } = key2number(key);

    const [module, setModule] = useState<DescriptyonType>({ id: 0 });

    /*     useEffect(() => {
            //load(id);
            setModule(description ? description : {});
        }, [description]); */



    const change = async (props: any) => {
        console.log("ch description", props);

        let formData = new FormData();
        formData.append("query", JSON.stringify({ ...props, descriptionID: props?.id, type: "description" }));
        submit(formData, { method: "POST", /* action: String(props?.id) */ });

        //        setModule({ ...module, ...value });
        //        modyfyDescriptionO({ descriptionID: module.id, ...value });
    }

    return (
        <>
            {descriptions.map((description: DescriptyonType) => {
                return (
                    <Card title={'Задание: ' + description.description} key={'description' + description.id}>
                        <Row gutter={[4, 4]}>
                            <Col span={6}>
                                <b>Порядковый номер</b>
                            </Col>
                            <Col span={18}>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Порядковый номер',
                                        onChange: (index) => change({ id: description.id, index }),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {String(description.index)}
                                </Paragraph>
                            </Col>
                            <Col span={6}>
                                <b>Дидактика</b>
                            </Col>
                            <Col span={18}>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Введите название',
                                        onChange: (_desc) => change({ id: description.id, description: _desc }),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {description.description}
                                </Paragraph>
                            </Col>
                            <Col span={6}>
                                <b>Параметры</b>
                            </Col>
                            <Col span={18}>
                                <MyColoredSelect
                                    onChange={(value: any) => change({ id: description.id, workType: value?.value })}
                                    name="workType" /* style={{ width: '100%' }}  */
                                    placeholder='Выберите тип'
                                    value={colors.filter((item: any) => item.value === description.workType)}
                                    options={colors}
                                />

                            </Col>
                            <Col span={6}>
                                <b>Самооценка</b>
                            </Col>
                            <Col span={18}>
                                <Checkbox
                                    onChange={(value: any) => change({ id: description.id, selfScore: value?.target?.checked })}
                                    name="selfScore" /* style={{ width: '100%' }}  */
                                    checked={description.selfScore}
                                />

                            </Col>
                            <Col span={24}>
                                <b>Задание урока</b>
                            </Col>
                            <Col span={18}>

                                <Editor
                                    data={description.text}
                                    /* idToSave={description.id} */
                                    funcToSave={(text: any) => change({ id: description.id, text })/* changeText(val, description.id) */}
                                    placeholder='Введите описание'
                                ></Editor>

                                <FilesUpload
                                    fileList={description.fileList}
                                    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'descriptions'}&id=${description?.id}`}
                                />
                            </Col>

                            <Col span={6}>

                            </Col>
                            <Col span={18}>
                                <Form
                                    action={'destroyDescription/' + String(description?.id)}
                                    method="post"
                                    onSubmit={(event) => {
                                        console.log(event);

                                        if (
                                            !window.confirm(`Подтвердите удаление`)
                                        ) {
                                            event.preventDefault();
                                        }

                                    }}

                                >
                                    <Button size="sm" type="submit" className='float-end btn btn-outline-danger'>
                                        <BiTrash className="justify-content-between" />
                                        Удалить задание
                                    </Button>
                                </Form>
                            </Col>

                        </Row>
                    </Card>

                )
            })}
            <Form method="post" action={"newDescription"}>
                <Button size="sm" type="submit">Добавить задание</Button>
            </Form>
        </>
    )
}


export default DescriptionCardList;