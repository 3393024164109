import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl, Form } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createSSubject, deleteSSubject, fetchSSubjects, fetchSSubjectsDesc, modyfySSubject, modyfySSubjectDesc } from '../../../http/adminApi';
import { Card, Col, message, Row, Slider } from 'antd';
import Editor from '../../utilites/quill/Editor';

const AdminRefSkillStarDetails = observer(() => {
    const { admin } = useContext(Context)
    //const [starSubjects, setStarSubjects] = useState([])
    const [selectedSSubject, setSelectedSSubject] = useState(0)
    const [selectedLayer, setSelectedLayer] = useState(1)
    const [starStudentDesc, setStarStudentDesc] = useState({})
    const [starTeacherDesc, setStarTeacherDesc] = useState({})

    const [buttonStudSaveDisabled, setButtonStudSaveDisabled] = useState(true)
    const [buttonTeacSaveDisabled, setButtonTeacSaveDisabled] = useState(true)

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        loadDescData()
    }, [selectedSSubject, selectedLayer])

    const loadData = async () => {
        const dataS = await fetchSSubjects()
        admin.setStarSubjects(dataS)
        setSelectedSSubject(dataS[0].id)
        //setStarSubjects(dataS)
    }

    const loadDescData = async () => {
        if (selectedSSubject != 0) {
            const dataStudent = await fetchSSubjectsDesc(selectedLayer, 'student', selectedSSubject)
            if (dataStudent) {
                setStarStudentDesc(dataStudent)
            } else {
                setStarStudentDesc({})
            }
            const dataTeacher = await fetchSSubjectsDesc(selectedLayer, 'tutor', selectedSSubject)
            if (dataTeacher) {
                setStarTeacherDesc(dataTeacher)
            } else {
                setStarTeacherDesc({})
            }
            // console.log("FDF", dataStudent?.description, dataTeacher?.description);
        }
    }

    const saveDesc = async (key) => {
        try {
            let description = ''
            let id = 0
            switch (key) {
                case 'student':
                    description = starStudentDesc.description
                    id = starStudentDesc.id
                    break;
                case 'tutor':
                    description = starTeacherDesc.description
                    id = starTeacherDesc.id
                    break;
            }
            modyfySSubjectDesc(description, id, selectedLayer, key, selectedSSubject).then(data => {
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    switch (key) {
                        case 'student':
                            setButtonStudSaveDisabled(true)
                            break;
                        case 'tutor':
                            setButtonTeacSaveDisabled(true)
                            break;
                    }
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }

    }

    return (
        <Card title={'Редактирование описаний лучей'}>
            <Row justify="space-around" align="middle">
                <Col span={16}>
                    <Form.Select onChange={(e) => setSelectedSSubject(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={selectedSSubject != 0 ? selectedSSubject : admin.starSubjects[0]?.id} aria-label="Default select example">
                        {admin.starSubjects.map(ssubj =>
                            <option key={'ssubj' + ssubj.id} value={ssubj.id}>{ssubj.name}</option>
                        )}
                    </Form.Select>
                </Col>
                <Col span={8}>
                    <Slider
                        style={{marginLeft:'15px'}}
                        //trackStyle={starLayer == 1 ? { backgroundColor: "#75B53E" } : { backgroundColor: "#ffd110" }}
                        defaultValue={selectedLayer}
                        disabled={admin.starSubjects.length == 0}
                        // tooltip={{ open: true }}
                        max={4}
                        min={1}
                        onChange={(value) => setSelectedLayer(value)}
                    />
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col span={24} align="center">
                    Описание для студента
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col span={20}>
                    <Editor
                        data={starStudentDesc.description}
                        edit={true}
                        funcToSave={(val) => {
                            setButtonStudSaveDisabled(false)
                            setStarStudentDesc({ ...starStudentDesc, description: val })
                        }}
                        placeholder='Введите описание для студента'
                    ></Editor>
                </Col>
                <Col span={4} align="center">
                    <Button disabled={buttonStudSaveDisabled}
                        variant="outline-success"
                        onClick={() => saveDesc('student')}>Сохранить</Button>
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col span={24} align="center">
                    Описание для тьютора
                </Col>
            </Row>
            <Row justify="space-around" align="middle">
                <Col span={20}>
                    <Editor
                        data={starTeacherDesc.description}
                        edit={true}
                        funcToSave={(val) => {
                            setButtonTeacSaveDisabled(false)
                            setStarTeacherDesc({ ...starTeacherDesc, description: val })
                        }}
                        placeholder='Введите описание для тютора'
                    ></Editor>
                </Col>
                <Col span={4} align="center">
                    <Button disabled={buttonTeacSaveDisabled}
                        variant="outline-success"
                        onClick={() => saveDesc('tutor')}>Сохранить</Button>
                </Col>
            </Row>

        </Card>
    );
});

export default AdminRefSkillStarDetails;