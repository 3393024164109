import Select, { StylesConfig } from 'react-select'
import chroma from 'chroma-js';


/* import styled from 'styled-components'

const CheckboxContainer = styled.div`

    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    //justify-content: center;

label {
    font-size: 1.0em;
    cursor: pointer;
    display: flex;
    &:hover{
        &::before{
            background-color: gray;
        }
    }
}

input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

input:checked {
    &+label::before{
        content: '\\002714';
        background-color: #eeb004;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

input:focus {
    &+label::before{
        box-shadow: 0 0 10px blue;
    }
}

label::before {
    //font-size: 0.9em;
    content: '';
    border: 0.05em solid gray;
    height: 1em;
    width: 1em;
    border-radius: 0.15em;
    margin-right: 0.5em;
}
`
 */
export type ColourOption = {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
};

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 3,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 15,
        width: 15,
    },
});


const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            ...dot(data.color),
            /*             backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                                ? data.color
                                : isFocused
                                    ? color.alpha(0.1).css()
                                    : undefined, */
            /*             color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? chroma.contrast(color, 'white') > 2
                                    ? 'white'
                                    : 'black'
                                : data.color, */
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,

            },
        };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const MyColoredSelect = (props: any) => (
    <Select
        {...props}
        //options={colors}
        styles={colourStyles}
    />
)


export default MyColoredSelect;