import { ReactNode, useMemo, useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";
import { useTable } from 'react-table'
import { fetchClass, fetchSilabusV2, fetchSilabusV2Data, fetchSubject, fetchTact, setScore } from "../../http/teacherApi";

import dayjs, { Dayjs } from 'dayjs'
dayjs.locale('ru');

interface Trecord {
    key?: string,
    id?: string,
    accessor?: string,
    Header?: string,
    columns?: Trecord[] | [],
    style?: React.CSSProperties
}

const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleSkills = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleDescriptions: React.CSSProperties = { background: '#efefef', padding: '3px', border: 'solid 1px gray'/* , writingMode: 'vertical-rl' */, minHeight: '400px', minWidth: '50px', fontSize: '0.rem', verticalAlign: 'top' };

export async function actionSilabusTableV2({ params, request }: any) {
    const formData = await request.formData();
    const { data } = Object.fromEntries(formData);
    if (data) {
        const event2 = await JSON.parse(data)
        console.log("SilabusTableV2", data, event2);
        setScore(event2);//id, score, answer, descID, studentID
        //modyfyEventO(event2)
    }


    return { data }
}


export async function silabusTableV2Loader({ params }: any) {
    console.log("silabusTableV2Loader", params);

    const { selectedClassSubject, selectedTact } = params;
    const [classID, subjectID] = selectedClassSubject?.split("|") || [1, 1]

    const classData = await fetchClass(classID);
    const subjectData = await fetchSubject(subjectID);

    if (selectedTact) {
        const tact = await fetchTact(selectedTact);
        const { teachingBegin, researchingEnd } = tact;
        if (teachingBegin && researchingEnd) {
            const modules = await fetchSilabusV2(classID, subjectID, teachingBegin, researchingEnd);

            var data3 = await fetchSilabusV2Data(classID, subjectID, teachingBegin, researchingEnd);
            var data4 = data3.map((item: any) => { return { ...item, 'd-14': '1', } }) // синтет данные по оценкам
            const [m_columns, m_modules] = generate_modules(modules);

            const silabus: Trecord[] = [{
                Header: '   ',
                columns: [{
                    Header: 'N п/п',
                    accessor: 'id'
                }, {
                    Header: 'ФИ студента',
                    accessor: 'name',
                    style: styleModules,
                }]
            }, {
                Header: 'Предмет:',
                style: { background: '#bfbfbf', padding: '4px', border: 'solid 1px gray' },
                columns: m_columns /*[{
            Header: 'Модуль',
            style: styleModules,
              columns: [{
                Header: 'Темы',
                style: styleThemes,
                columns: [{
                  Header: 'Умения',
                  style: styleRoutes,
                  columns: [{
                    Header: 'Уровень',
                    style: styleRoutes,
                    columns: [{
                      Header: 'Оценивание'
                    }]
                  }]
                }]
              }]
          }]*/
            }, {
                Header: `${subjectData.name} / ${classData.name}`,
                columns: m_modules,
                style: { background: '#ffffff', padding: '4px', border: 'solid 1px gray' }
            }]
            return { silabus, data: data4, modules, selectedClassSubject };
        }


    }

    return { silabus: [], data: [], modules: [] }

}


function generate_modules(modules: any) {
    const _header = { Header: 'Модуль', style: styleModules, }
    const output_array: any[] = [];
    if (Array.isArray(modules) && modules.length > 0) {
        var t_header = [];
        for (const module of modules) {
            const [header, themes] = generate_themes(module.themes);
            t_header = header
            output_array.push({
                //          Header: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!", accessor: "module" + module.id, id: "module" + module.id, style: styleModules
                Header: (module.name) ? `${module.name}` : "!", columns: themes, accessor: "module" + module.id, /* key: "module" + module.id, */ style: styleModules
            });
        }
        return [[{ ..._header, columns: t_header }], output_array]
    } else {
        return [[_header], [{ Header: 'Добавте модуль', /* accessor: 'module',  */id: "module", style: styleModules }]];
    }
}

function generate_themes(themes: any) {
    //console.log("themes", themes);

    const _header = { Header: 'Темы', style: styleThemes, }
    const output_array: any[] = [];
    if (Array.isArray(themes) && themes.length > 0) {
        var s_header: any = [];
        var s_skills: any = [];
        for (const theme of themes) {
            const [header, skills] = generate_skills(theme.skills);
            const [header1, descriptions] = generate_descriptions(theme.descriptions)
            //console.log("header", [{...header[0], columns: header1}]);

            //s_header = header
            s_header = [{ ...header[0], columns: header1 }]
            s_skills = [{ ...skills[0], columns: descriptions }]
            output_array.push({ Header: (theme.name) ? `${theme.name} (до ${dayjs(theme.end).format('DD MMM')})` : "Нет темы", columns: s_skills, id: "theme-" + theme.id, style: styleThemes })
        }
        return [[{ ..._header, columns: s_header }], output_array]
    } else {
        return [[_header], [{ Header: 'Добавте тему', accessor: 'theme', id: "theme", style: styleModules }]];
    }
}


export const style1: React.CSSProperties = { background: '#d4ecff', padding: '3px'/* , minHeight: '300px', minWidth: '250px' */, justifyItems: 'flex-start', position: 'relative', margin: '0px 0px 2px 0px ', };

function generate_skills(skills: any) {
    const _header = [{ Header: 'Умения', style: styleRoutes, }]
    const output_array: any[] = [];
    var text: ReactNode = (<div></div>);
    if (Array.isArray(skills) && skills.length > 0) {
        text = skills.map((skill) => { return (<div style={style1}>{(skill.name) ? skill.name : "!"}</div>) })
        /*
            for (const skill of skills) {
              console.log(SkillViever((skill.name) ? skill.name : "!"));
              
              text.appe = SkillViever((skill.name) ? skill.name : "!");
              //output_array.push({ Header: (skill.name) ? skill.name : "!", id: "skill-" + skill.id, style: { ...styleRoutes, background: skill.color } })
            }
        */

        output_array.push({ Header: text, id: "skill-" + Math.random(), style: { ...styleRoutes, /* , background: skill.color */ } })
        return [_header, output_array]
    } else {
        return [_header, [{ Header: ' ', /* accessor: 'skill', */ id: "skill" + Math.random(), style: styleSkills }]];
    }
}

function generate_descriptions(descriptions: any) {
    const _header = [{ Header: 'Уровень', id: 'level', style: styleDescriptions, }]
    const output_array: any[] = [];
    if (Array.isArray(descriptions) && descriptions.length > 0) {
        for (const description of descriptions) {
            output_array.push({ Header: description?.description || '?', /* id: "d-" + description.id, */ accessor: "d-" + description.id, editable: true, style: { ...styleDescriptions, background: description?.params?.color } })
        }
        return [_header, output_array]
    } else {
        return [_header, [{ Header: ' ', /* accessor: 'description', */ id: "description" + Math.random(), style: styleDescriptions }]];
    }
}




const SilabusTableV2 = () => {

    const { data, silabus } = useLoaderData() as any;
    const submit = useSubmit();

    console.log("silabus data: ", silabus);
    console.log("summ data: ", data);

    const CustomInputComponent = ({ /* value = '',  */onChange, cell = {} }: any) => {
        const value = cell.value
        // cell.column.id
        // cell.row.original.id
        const [inputValue, setInputValue] = useState(value);

        const handleInputChange = (e: any) => {
            setInputValue(e.target.value);
        };

        const handleInputBlur = (e: any) => {
            console.log("blur", e.target.value, cell.column.id, cell.row.original.id);
            const [d, descID] = cell.column.id.split('-');
            const data = { teacherScore: e.target.value, descID, studentID: cell.row.original.id }
            //            onChange(e.target.value);
            let formData = new FormData();
            formData.append("data", JSON.stringify(data));
            submit(formData, { method: "POST" });
        };

        return (
            <input style={{ width: '100%', textAlign: 'center', border: '0px solid #ff8c00', background: '#ffd110' }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            // Additional props and styling
            />
        );
    };

    //    const columns = useMemo(() => silabus, [])
    const columns = silabus

    const tableInstance = useTable({
        columns,
        data,
    })

    const handleEditClick = (rowId: any) => {
        console.log("edit click", rowId);

        /* setEditRowId(rowId.row);
        setEditColumnId(rowId.column); */
    };


    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance

    return (<div style={{ width: '100%' }}>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup: any) => (
                    <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                {...column.getHeaderProps([{
                                    style: column.style
                                }])}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row: any) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                                if (cell.column.id === 'level') {

                                } else
                                    return <td {...cell.getCellProps()} style={{...{ border: '1px solid gray' }, ...cell.column.style}} /* onClick={() => { console.log(cell.column.id, cell.row.original) }} */ {...(cell.column.id === 'name') ? ({ colSpan: 2 }) : ('')}>
                                        {
                                            //console.log("-->", cell)
                                        }
                                        {(cell.column.editable/* editRowId === row.id *//* cell.column.id === editColumnId && cell.row.original.id === editRowId */) ? (
                                            /* Render custom input field for editing */
                                            <>
                                                <CustomInputComponent
                                                    cell={cell}
                                                    //value={cell.value}
                                                    onChange={(newValue: any) => {
                                                        // Logic to update local state with the new value
                                                    }}
                                                />
                                                {/* <button onClick={() => handleSaveClick(row.id, row.values)}>Save</button>
                      <button onClick={() => handleCancelClick(row.id)}>Cancel</button> */}
                                            </>
                                        ) : (
                                            /* Render static text */
                                            <div onClick={() => handleEditClick(/* row.id */{ column: cell.column.id, row: cell.row.original.id })}>
                                                {cell.render('Cell')}
                                                {/* <button onClick={() => handleEditClick(row.id)}>Edit</button> */}
                                            </div>
                                        )
                                        }
                                    </td>
                            })}
                        </tr>
                    )
                })
                }
            </tbody>
        </table>
    </div>
    )
}

export default SilabusTableV2;