import { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { fetchClassSubjects, modyfyClassSubjects } from '../../../http/adminApi';
import { Card, Col, Row, Tabs, message } from 'antd';
import { FaBan, FaEdit, FaPlus, FaSave } from 'react-icons/fa';


import { Button, Form } from 'react-bootstrap';
import { Context } from '../../..';
import MyCheckbox from '../../MyCheckbox';
import { Outlet, useSubmit } from 'react-router-dom';


const AdminRefSubjectStarCheckBox = observer(() => {
    const [subjects, setSubjects] = useState<any>([]);
    const [classID, setClassID] = useState<string>('0');
    const { admin } = useContext(Context);
    /* //() => { // не понятно откуда взявшийся кусок кода, если нет косяков с суьектной звездой, удалить нахер
    const submit = useSubmit();
//         const [students, setStudents] = useState([])
//        const [parents, setParents] = useState([])
//        const [teachers, setTeachers] = useState([])
//        const [tutors, setTutors] = useState([])
//        const [admins, setAdmins] = useState([]) 

//     useEffect(() => {
//            userList('student').then(data => setStudents(data))
//            userList('parent').then(data => setParents(data))
//            userList('teacher').then(data => setTeachers(data))
//            userList('tutor').then(data => setTutors(data))
//            userList('ADMIN').then(data => setAdmins(data))
//        }, []) 
    const style = { textDecoration: 'none', color: 'black', hover: 'color: #4096ff' }

    const items = [
        {
            label: 'Предметы',
            children: <></>,
            key: 'subjects',
        }, {
            label: 'Типы контроля',
            children: <></>,
            key: 'worktypes',
        }, {
            label: 'Цвета',
            children: <></>,
            key: 'colors',
        }, {
            label: 'Сетки расписания',
            children: <></>,
            key: 'grids',
        }, {
            label: 'Места',
            children: <></>,
            key: 'locations',
        }, {
            label: 'Типы событий',
            children: <></>,
            key: 'events',
        }, {
            label: 'Ответственные',
            children: <></>,
            key: 'responsible',
        }, {
            label: 'Субьектная звезда',
            children: <></>,
            key: 'refskillstar',
        }, {
            label: 'Учебные такты',
            children: <></>,
            key: 'reftacts',
        }, {
            label: 'Предметная звезда',
            children: <></>,
            key: 'refsubjectstar',
        }
    ];

    const onChange = (key:string) => {
        console.log(key);
//        let formData = new FormData();
//        formData.append("event", JSON.stringify({}));
        submit(null, { method: "GET", action: key });
        return

        
    }
 
    
    return (
        <Card>
            <Tabs
                type="card"
                items={items}
                onChange={onChange}
            />
            <Outlet />
        </Card>
    );
}

 */
   const subjectsLoader = async () => {

        const cs = await fetchClassSubjects(classID);
        setSubjects(cs);
        console.log("subject loader refs", cs);
        /*         for (const item in cs) {
                    console.log(item);
        
                }  */
    }

    useEffect(() => {
        //console.log("selected class changed");
        subjectsLoader();

    }, [classID]);

    const save = async () => {
        const data = { classID, subjects }
        try {
            const ret = await modyfyClassSubjects(data);
            if (ret) {
                message.success("успешно сохранено");
            } else {
                message.error("ошибка сохранения");

            }
        } catch {
            message.error("ошибка сохранения");

        }
        //        console.log(subjects);

    }


    const onChange = (e: any) => {
        const id = e.target.id;
        const star = e.target.checked;

        //console.log(e.target.checked, e.target.id);
        //message.success("сохранено");

        const newData = [...subjects];
        const index = newData.findIndex((item: any) => id == item.id);
        //console.log("index", index);
        if (index > -1) {
            const item = newData[index];
            //console.log("item", item);

            newData.splice(index, 1, {
                ...item,
                ...{ star }
            });
        } else {
            //            newData.push(editingItem);
            //            setData(newData);
        }
        setSubjects(newData);
        //        console.log(newData);





    }
    return (
        <>
            <Card>

                <Row>
                    <Col span={24}>
                        <Form.Select onChange={(e) => setClassID(e.target.value)}>
                            {admin.classes.map(clas =>
                                <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                            )}
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ul style={{ listStyleType: 'none' }}>
                            {subjects.map((item: any) => (
                                <li key={item?.id + '-' + classID}>
                                    <MyCheckbox id={item?.id} labelTxt={item?.name} onChange={onChange}  checked={(item?.star) ? true : false} />
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={save}>Сохранить</Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
});

export default AdminRefSubjectStarCheckBox;