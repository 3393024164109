import React from 'react';
import { observer } from "mobx-react-lite";
import { Card, Tabs } from 'antd';
import AdminRefSubjects from './AdminRefSubjectsN';
import AdminRefWorkTypes from './AdminRefWorkTypes';
import AdminRefColors from './AdminRefColors';
import AdminScheduleGridRef from '../schedules/AdminScheduleGridRef';
import AdminRefSubjectStarCheckBox from './AdminRefSubjectStarCheckBox';
import AdminRefSkillStarDetails from './AdminRefSkillStarDetails';
import AdminRefCalendarLocations from './AdminRefCalendarLocations';
import AdminRefCalendarEventTypes from './AdminRefCalendarEventTypes';
import AdminRefResponsible from './AdminRefResponsible';
import AdminRefTacts from './AdminRefTacts';
import { Navigate, NavLink, Outlet, useSubmit } from 'react-router-dom';

const AdminRefsTabs = observer(() => {
    const submit = useSubmit();
    /*     const [students, setStudents] = useState([])
        const [parents, setParents] = useState([])
        const [teachers, setTeachers] = useState([])
        const [tutors, setTutors] = useState([])
        const [admins, setAdmins] = useState([]) */

    /*     useEffect(() => {
            userList('student').then(data => setStudents(data))
            userList('parent').then(data => setParents(data))
            userList('teacher').then(data => setTeachers(data))
            userList('tutor').then(data => setTutors(data))
            userList('ADMIN').then(data => setAdmins(data))
        }, []) */
    const style = { textDecoration: 'none', color: 'black', hover: 'color: #4096ff' }

    const items = [
        {
            label: 'Предметы',
            children: <></>,
            key: 'subjects',
        }, /* {
            label: 'Типы контроля',
            children: <></>,
            key: 'worktypes',
        }, */ {
            label: 'Цвета',
            children: <></>,
            key: 'colors',
        }, {
            label: 'Сетки расписания',
            children: <></>,
            key: 'grids',
        }, {
            label: 'Места',
            children: <></>,
            key: 'locations',
        }, {
            label: 'Типы событий',
            children: <></>,
            key: 'events',
        }, {
            label: 'Ответственные',
            children: <></>,
            key: 'responsible',
        }, {
            label: 'Субьектная звезда',
            children: <></>,
            key: 'refskillstar',
        }, {
            label: 'Учебные такты',
            children: <></>,
            key: 'reftacts',
        }, {
            label: 'Предметная звезда',
            children: <></>,
            key: 'refsubjectstar',
        }
    ];

    const onChange = (key:string) => {
        console.log(key);
//        let formData = new FormData();
//        formData.append("event", JSON.stringify({}));
        submit(null, { method: "GET", action: key });
        return

        
    }

    
    return (
        <Card>
            <Tabs
                type="card"
                items={items}
                onChange={onChange}
            />
            <Outlet />
        </Card>
    );
});

export const refAdminRouter = [
    { index: true, element: < Navigate to='subjects' /> },
    { path: 'refsubjectstar', element: <AdminRefSubjectStarCheckBox /> },
    { path: 'reftacts', element: <AdminRefTacts /> },
    { path: 'refskillstar', element: <AdminRefSkillStarDetails /> },
    { path: 'responsible', element: <AdminRefResponsible /> },
    { path: 'events', element: <AdminRefCalendarEventTypes /> },
    { path: 'locations', element: <AdminRefCalendarLocations /> },
    { path: 'grids', element: <AdminScheduleGridRef /> },
    { path: 'colors', element: <AdminRefColors /> },
    /* { path: 'worktypes', element: <AdminRefWorkTypes /> }, */
    { path: 'subjects', element: <AdminRefSubjects /> },
]

export default AdminRefsTabs;