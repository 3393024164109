import { Card, Col, Row } from "antd"
import { Form, Outlet, redirect, useLoaderData, useSubmit } from "react-router-dom"
import Select from 'react-select'
import { fetchTeacherClassesSubjects } from "../../../http/teacherApi";
import styled from 'styled-components'



export async function selectClassSubjectLoader({ params }: any) {
    const rawClassesSubjects = await fetchTeacherClassesSubjects();
    const classesSubjects = rawClassesSubjects.map((classub: any) => { return ({ value: classub.id, label: classub.className + ' / ' + classub.subjectName }) })
    //console.log("silabusLoader", params);
    return { classesSubjects };
}

export async function selectClassSubjectAction({ params, request }: any) {
    const formData = await request.formData();
    const {query} = Object.fromEntries(formData);
    if (query) console.log("modules2Action", JSON.parse(query));
    
    const {classsubject} = Object.fromEntries(formData);
    if (classsubject) return redirect(`${classsubject}`);
  
    /*   console.log("modules actions: ", params, classsubject); */
      return redirect('.')
  }
  
  

interface SelectOptions {
    value: number
    label: string
}

const StyledSelect = styled(Select)`
    width: fit-content;
    block-size: fit-content;
    z-index: 99999;
    margin: 4px;
`;

export const SelectClassSubject = () => {
    const submit = useSubmit();
    const { classesSubjects } = useLoaderData() as { classesSubjects: SelectOptions[] };


    const onSelect1 = (e: any) => {
        //console.log(e);

        let formData = new FormData();
        formData.append("classsubject", e?.value);
        formData.append("classsubjectname", e?.label);
        submit(formData, { method: "POST" });
    }

    return (
         /*<Card style={}>
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    </Col><Form  method="POST" > */
                        <StyledSelect /* styles={styles} */
                            //key={'csdfsdf'}
                            onChange={onSelect1}
                            name="classsubject" /* style={{ width: '100%' }}  */
                            placeholder='Выберите класс/предмет'
                            //value={classesSubjects.filter((item: any) => item.value === selectedClassSubject)}
                            options={classesSubjects}
                        />
                    /* </Form>
                </Col>
            </Row>
        </Card> */

    )
}
