import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createColor, deleteColor, fetchColors, modyfyColor } from '../../../http/adminApi';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Checkbox } from 'antd';

const AdminRefColors = observer(() => {
    // const { admin } = useContext(Context)
    const [colors, setColors] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let data = await fetchColors()
        setColors(data)
    }

    const addColor = () => {
        const data = createColor().then(id =>
            setColors([...colors, { name: '', color: 'black', id: id }]))
    }

    const removeColor = async (id) => {
        let data = await deleteColor(id)
        setColors(colors.filter(i => i.id !== id))
    }

    const change = async (field, id) => {
        console.log(field);
        
        let data = await modyfyColor(field)
        setColors(colors.map(i => i.id === field['id'] ? { ...i, ...field } : i))
    }

/*     const changeColorName = async (name, id) => {
        let data = await modyfyColor(name, null, id)
        setColors(colors.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeColor = async (color, id) => {
        let data = await modyfyColor(null, color, id)
        setColors(colors.map(i => i.id === id ? { ...i, ['color']: color } : i))
    }

    const changeEventTypeName = async (name, id) => {
        let data = await modyfyCalendarEventType(name, null, id, null, null)
        setEventTypes(eventTypes.map(i => i.id === id ? { ...i, ['name']: name } : i))
    } */

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Параметры задания</th>
                        <th></th>
                        <th>в итог</th>
                        <th>среднее</th>
                        <th><Button onClick={() => addColor()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {colors.map(color =>
                        <tr key={color.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Название цвета',
                                        onChange: (value) => change({name:value, id:color.id}),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {color.name}
                                </Paragraph>
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Выберете цвет'
                                    value={color.color}
                                    onChange={(e) => change({color:e.target.value, id:color.id})}
                                    type="color"
                                />
                            </td>
                            <td>
                                <Checkbox
                                    onChange={(e) => change({ result: e.target.checked, id: color.id })}
                                    defaultChecked={color.result}
                                //disabled={true}
                                >
                                    {/* {color.isWeek ? 'Да' : 'Нет'} */}
                                </Checkbox>
                            </td>
                            <td>
                                <Checkbox
                                    onChange={(e) => change({avg:e.target.checked, id:color.id})}
                                    defaultChecked={color.avg}
                                //disabled={color.isSystem}
                                >
                                    {/* {color.isWeek ? 'Да' : 'Нет'} */}
                                </Checkbox>
                            </td>
                            <td><Button onClick={() => removeColor(color.id)} className='float-end'><FaMinus className="d-flex justify-content-between" /></Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefColors;