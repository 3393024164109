import { Button, Card, Col, DatePicker, Row } from "antd";
import { Typography } from 'antd';
import { useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createRoute, deleteRoute, fetchModules, modyfyRouteO, oneModule, oneRoute, oneTheme } from "../../http/teacherApi";
import dayjs from 'dayjs'
import MyDatePicker from "../MyDatePicker";
import Select, { StylesConfig } from 'react-select'
import { fetchColors } from "../../http/adminApi";
import chroma from 'chroma-js';
import { Form, redirect, useLoaderData } from "react-router-dom";

const { Paragraph } = Typography;
dayjs.locale('ru')

interface Module {
    id: number,
    index?: string,
    name?: string,
    begin?: string | null,
    end?: string,
    color?: string,
}

export type ColourOption = {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
};

export async function loaderRouteCard({ params }: any) {
    const { routeID } = params;
    if (routeID) {
        const routes = await oneRoute(routeID);
        const rawColors = await fetchColors();
        const colors = rawColors.map((item: any) => { return { value: item.id, label: item.name, color: item.color } });
        return { routes, colors, routeID }
    }
}

export async function createRouteCard({ params }: any) {
    const { themeID } = params;
        const ret = await createRoute(themeID);
        console.log("createRouteCard: new routeID:", ret);
        return redirect(ret);
}

export async function destroyRouteCard({ params, request }: any) {
    console.log("destroyRouteCard", params, request);
    const { themeID, routeID, moduleID } = params
    if (routeID) {
        await deleteRoute(routeID);

    }
    return redirect(`../../${moduleID}/${themeID}`);
}



export async function actionRouteCard({ params, request }: any) {
}

/* export const colourOptions: readonly ColourOption[] = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];
 */
const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 3,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 15,
        width: 15,
    },
});


const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            ...dot(data.color),
            /*             backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                                ? data.color
                                : isFocused
                                    ? color.alpha(0.1).css()
                                    : undefined, */
            /*             color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? chroma.contrast(color, 'white') > 2
                                    ? 'white'
                                    : 'black'
                                : data.color, */
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,

            },
        };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const RouteCard = (/* { id }: number | any */) => {
    const { routes, colors, routeID} = useLoaderData() as any;

    //const [colors, setColors] = useState([])

    const [module, setModule] = useState<Module>({ id: 0 });


    useEffect(() => {
        setModule(routes)
        //        load(id);
    }, [routes]);

/*     const load = async (id: any) => {
        const ret = await oneRoute(id);
        setModule(ret);
        const rawColors = await fetchColors();
        setColors(rawColors.map((item: any) => { return { value: item.id, label: item.name, color: item.color } }));
    } */

    const change = async (value: any) => {
        console.log("ch idx", value, routeID);

        setModule({ ...module, ...value });

        modyfyRouteO({ routeID: module.id, ...value })
    }

    return (
        <Card title={'Умение: ' + module.name} key={'route' + module.id}>
            <Row gutter={[4, 4]}>
                <Col span={6}>
                    Порядковый номер
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Порядковый номер',
                            onChange: (value) => change({ index: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {String(module.index)}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Умение
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Введите название',
                            onChange: (value) => change({ name: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {module.name}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Цвет
                </Col>
                <Col span={18}>
                    <Select
                        onChange={(value: any) => change({ color: colors.filter((item: any) => item.value === value.value)[0]['color'] })}
                        name="color" /* style={{ width: '100%' }}  */
                        placeholder='Выберите цвет'
                        value={colors.filter((item: any) => item.color === module.color)}
                        options={colors}
                        styles={colourStyles}
                    />
                </Col>

                <Col span={6}>
                </Col>
                <Col span={18}>
                <Form
                        action={/* String(module1?.id) + */ 'destroy'}
                        method="post"
                        onSubmit={(event) => {
                            console.log(event);

                            if (
                                !window.confirm(`Подтвердите удаление`)
                            ) {
                                event.preventDefault();
                            }

                        }}

                    >
                        <button className='float-end btn btn-outline-danger'>
                            <BiTrash className="justify-content-between" />
                            Удалить умение
                        </button>
                    </Form>
                </Col>

            </Row>
        </Card>
    )
}


export default RouteCard;