import { redirect, useLoaderData } from "react-router-dom";
import DescriptionCardList, { loaderDescriptionCardList } from "../DescriptionCardList";
import SkillsListCard, { loaderSkillsListCard } from "../SkillsListCard";
import { deleteDescriptionV2, deleteRouteV2, modyfyDescriptionOV2, modyfyRouteOV2 } from "../../http/teacherApi";

export async function loaderSkillsDescriptions({ params }: any) {
    console.log("loaderSkillsDescriptionsCard", params);

    const skillsListCard = await loaderSkillsListCard({ params })
    const descriptionsCardList = await loaderDescriptionCardList({ params })

    /*     const rawWorkTypes = await fetchWorkTypes();
        const workTypes = rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } });
    
        const { routeID, moduleID, themeID, descriptionID } = params;
    
        const raw_key: string[] = []
        raw_key.push(moduleID);
        if (themeID) raw_key.push(themeID);
        if (routeID) raw_key.push(routeID);
        if (descriptionID) raw_key.push(descriptionID);
    
        const key = raw_key.join('-');
    
        const target = { id: moduleID + '-' + themeID };
    
        if (themeID) {
            const theme = await oneTheme(themeID);
    
            const routes = await fetchRoutes(themeID);
    
            return { theme, workTypes, routes, target, key }
        }
     */



    return { ...skillsListCard, ...descriptionsCardList }
}

export async function actionSkillsDescriptions({ params, request }: any) {
         const formData = await request.formData();
        const { query } = Object.fromEntries(formData);
    
        if (query) {
            console.log("actionSkillsDescriptions 3", params, JSON.parse(query));
            const { id, name, type, index, key, workType, selfScore, color, routeID, descriptionID, text, description } = JSON.parse(query);
            if (routeID) {
                if (name === '') {
                    deleteRouteV2(routeID)
                    return redirect(".");
                }
                modyfyRouteOV2({ routeID, name, color, index });
                return redirect(".");
//                return redirect(routeID);
            }
            if (descriptionID) {
                if (name === '') {
                    deleteDescriptionV2(descriptionID)
                    return redirect(".");
                }
                modyfyDescriptionOV2({ descriptionID, text, description, index, workType, selfScore });
                return redirect(".");
            }
    
        }
    
        return redirect('..');
}


const SkillsDescriptions = (/* params: { id: number | any } */) => {
    const data = useLoaderData() as any;
console.log("summ data: ", data);

    return (<>
        <SkillsListCard />
        <DescriptionCardList />
    </>
    )
}

export default SkillsDescriptions;