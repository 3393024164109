import React from 'react'
import { shape } from './Shape';
import { axis } from './Points';
import { caption } from './Caption';
import "./RadarChart.css";
import { Tooltip } from 'antd';

export const RadarChart = ({ route, size, numberOfScale, navigate, tipsData, dotMode }) => {

    const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;
    const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

//     console.log("ROU",route);

    const scale = value => (
        <circle key={`scale-${value}`}
            cx={0}
            cy={0}
            r={(value / numberOfScale * size) / 2}
            fill="rgb(234,234,234)"
            stroke="rgb(0,0,0)"
            strokeWidth="0.2"
        />
    );

    const dot = (angle, value, key) => {
                console.log("dotmore", dotMode, tipsData, key);
        if (dotMode == 'normal') {
            return (
                <circle key={`dot-${angle}-${value}`}
                    cx={polarToX(angle, value / numberOfScale * size) / 2}
                    cy={polarToY(angle, value / numberOfScale * size) / 2}
                    r={2}
                    fill="rgb(0,0,0)"
                    stroke="rgb(0,0,0)"
                    strokeWidth="1"
                />
            )
        }
        if (dotMode == 'tips' && tipsData != undefined && tipsData[key]) {
//            console.log("dot", tipsData[key], value);
            // .replace(/<\/?[^>]+(>|$)/g, "") - убрать теги из строки
            let tip = tipsData[key][value - 1]
            let cleanTip
            if (tip) {
                cleanTip = tip.replace(/<\/?[^>]+(>|$)/g, "")
            }
            //tip = tip.replace(/<\/?[^>]+(>|$)/g, "")
            return (
                <Tooltip title={cleanTip} key={`tooltip-${angle}-${value}`}>
                    <circle key={`dot-${angle}-${value}`}
                        cx={polarToX(angle, value / numberOfScale * size) / 2}
                        cy={polarToY(angle, value / numberOfScale * size) / 2}
                        r={4}
                        fill="#1890ff"
                        stroke="#1890ff"
                        strokeWidth="1"
                    />
                </Tooltip>
            )
        }
    };

    /*     <svg>
    <circle cx="102" cy="102" r="100" fill="rgb(234,234,234)" stroke-width="1" stroke="rgb(0,0,0)"/>
    </svg> */

    const middleOfChart = (size / 2).toFixed(4);

    const groups = [];
    /*     const scales = [];
        for (let i = numberOfScale; i > 0; i--) {
            scales.push(scale(i));
        }
        groups.push(<g key={`scales`}>{scales}</g>); */

    const captions = Object.keys(route[0]);

    const columns = captions.map((key, i, all) => {
        //console.log("dfd",route[0][key])
        return {
            key,
            /* id: route[0][key][id], */
            angle: (Math.PI * 2 * i) / all.length
        };
    });

    const dots = [];
    /*  dots.push(dot(0, 0)); */
    for (let i = numberOfScale - 1; i > 0; i--) {
        columns.map((col) => dots.push(dot(col.angle, i, col.key)));
    }
    //groups.push(<g key={`dots`}>{dots}</g>);

    //groups.push(<g key={`group-captions`}>{columns.map(caption(polarToX, polarToY, size, navigate))}</g>)
    groups.push(<g key={`group-axes`}>{columns.map(axis(polarToX, polarToY, size))}</g>)
    groups.push(
        <g
            key={`groups`}>
            {route.map(shape(polarToX, polarToY, columns, size))}
        </g>
    )
// надписи
    groups.push(<g key={`group-captions`}>{columns.map(caption(polarToX, polarToY, size, navigate))}</g>)

    groups.push(<g key={`dots`}>{dots}</g>);
    //  console.log("GRO",groups);
    /*     groups.push(
            <g
                key={`groups0`}>
                {route[0](shape(polarToX, polarToY, columns, size, `#75b53e`))}
            </g>
        )
        groups.push(
            <g
                key={`groups1`}>
                {route[1](shape(polarToX, polarToY, columns, size, `#ffd110`))}
            </g>
        ) */
    return (
        <div style={{ maxWidth: '1000px' }}>
            <svg
                version="1"
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
            >
                <g transform={`translate(${middleOfChart}, ${middleOfChart})`}>{groups}</g>

            </svg>
        </div>
    )
}

export default RadarChart;